/* eslint-disable no-constant-condition */
import { Container, useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import React from "react";
import { Link } from "react-router-dom";
import Colors from "../Colors";
import AppleIcon from "@material-ui/icons/Apple";

export function HashLink({ children, style, ...props }) {
  return (
    <a
      {...props}
      style={{
        textDecoration: "none",
        cursor: "pointer",
        fontSize: 16,
        paddingLeft: 24,
        paddingRight: 24,
        color: Colors.Gray.G900,
        fontFamily: "Barlow",
        ...style,
      }}>
      {children}
    </a>
  );
}

export default function Bar(props) {
  const { container } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const drawerWidth = 0;

  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      height: matches ? 85 : undefined,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      borderBottom: "1.5px solid #F4F5F6",
      /*[theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },*/
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));

  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button component={Link} to={"/docs"}>
          <ListItemIcon>
            <DriveEtaIcon />
          </ListItemIcon>
          <ListItemText primary={"Documents"} />
        </ListItem>
      </List>
      <Divider />

      <div></div>
      <Divider />
    </div>
  );

  return (
    <>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Container style={{ maxWidth: 1440 }}>
          <Toolbar
            style={{
              paddingLeft: 0,
              maxWidth: 1440,
              paddingRight: 0,
              marginTop: matches ? 10 : 0,
            }}>
            <Box display="flex" flexGrow={1} style={{ marginLeft: 8 }}>
              <Link to="/">
                <img
                  alt="RV"
                  src="logotext.webp"
                  style={{
                    height: 48,
                    marginTop: 6,
                    marginRight: 4,
                    marginLeft: matches ? 0 : -16,
                  }}
                />
              </Link>
            </Box>

            <Box display="flex" flexGrow={1}></Box>

            <Button
              href="https://apps.apple.com/app/id1669035195"
              color="secondary"
              startIcon={<AppleIcon />}
              variant="contained"
              size={matches ? undefined : "small"}
              style={{ color: "#fff", maxWidth: 180 }}>
              Get the App
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      {false && <div className={classes.toolbar} />}
      {false && (
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}>
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open>
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      )}
    </>
  );
}
