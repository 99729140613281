import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Tracker from "@openreplay/tracker";

export const tracker = new Tracker({
  projectKey: "3rpDDYfucfn3G69SGI6C",
  // onStart: ({ sessionID }) => console.log("OpenReplay tracker started with session: ", sessionID),
});
tracker.start();

/*
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
});*/

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
