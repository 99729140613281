import { Button, Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { BodyMedium, Heading1 } from "../../../components/typography";
import ReactTypingEffect from "react-typing-effect";
import AppleIcon from "@material-ui/icons/Apple";

export default function HomeHeader() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const headerStyle = matches ? {} : { fontSize: 35, lineHeight: "40px" };
  const textStyle = matches ? {} : { fontSize: 14, maxWidth: 310 };

  return (
    <div style={{ backgroundColor: "#f7f7f7" }}>
      <Container
        style={{
          maxWidth: 1190,
          paddingTop: matches ? 230 : 100,
          paddingBottom: matches ? 120 : 20,
          textAlign: "center",
          backgroundSize: "contain",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}>
        <Heading1
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            fontWeight: "bold",
            lineHeight: "80px",
            color: "#16181A",
            textAlign: "center",
            ...headerStyle,
          }}>
          Finally, create social media posts you can be proud of -&nbsp;
          <ReactTypingEffect
            speed={100}
            eraseDelay={2000}
            eraseSpeed={10}
            typingDelay={10}
            text={["Crop", "Frame", "Style", "Share"]}
          />
        </Heading1>

        <BodyMedium
          style={{
            marginTop: matches ? 27 : 10,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 48,
            color: "#000",
            fontSize: 18,
            maxWidth: 560,
            ...textStyle,
          }}>
          The only post creator app that will make you look like a pro designer.
        </BodyMedium>

        <div>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<AppleIcon />}
            href="https://apps.apple.com/app/id1669035195"
            style={{ color: "#fff", width: 180 }}>
            Get the App
          </Button>

          <BodyMedium
            style={{
              fontSize: 18,
              color: "#000",
              marginBottom: 48,
              marginLeft: "auto",
              marginRight: "auto",
              ...textStyle,
            }}>
            See jaw-dropping posts!
          </BodyMedium>
        </div>

        {false && (
          <img
            src="images/postswag.webp"
            style={{ width: matches ? "50%" : "50%", marginTop: matches ? 60 : 40 }}
            alt="RV"
          />
        )}
      </Container>
    </div>
  );
}
