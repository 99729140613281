import { Container, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import Colors from "../../../components/Colors";
import { BodyLarge, BodyMedium, Heading3 } from "../../../components/typography";

function FAQ({ title, children }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [showAnswer, setShowAnswer] = React.useState(false);
  return (
    <div
      onClick={() => setShowAnswer(!showAnswer)}
      style={{
        borderBottom: "1.5px solid #F4F5F6",
        paddingBottom: 16,
        paddingTop: 16,
        cursor: "pointer",
      }}>
      <BodyLarge style={matches ? {} : { fontSize: 12 }}>
        <AddIcon style={{ color: "#A4DF58", marginBottom: -5, marginRight: 8 }} />
        {title}
      </BodyLarge>
      {showAnswer && (
        <BodyMedium
          style={{
            color: Colors.Gray.G500,
            padding: 0,
            marginTop: 8,
            marginBottom: 5,
            paddingLeft: 32,
            paddingRight: 16,
            ...(matches ? {} : { fontSize: 12 }),
          }}>
          {children}
        </BodyMedium>
      )}
    </div>
  );
}
export default function FAQs({ title, items }) {
  return (
    <>
      <div id="faq" style={{ marginTop: -80, marginBottom: 80 }} />

      <Container style={{ maxWidth: 780, marginTop: 16, marginBottom: 62 }}>
        <Grid
          style={{
            marginTop: 32,
            borderRadius: 24,
            paddingTop: 78,
            marginLeft: "auto",
            marginRight: "auto",
          }}>
          <Heading3
            style={{
              color: Colors.Gray.G900,
              textAlign: "center",
              marginBottom: 24,
            }}>
            {title}
          </Heading3>

          {items.map(({ q, a }) => (
            <FAQ key={q} title={q}>
              {a}
            </FAQ>
          ))}
        </Grid>
      </Container>
    </>
  );
}
