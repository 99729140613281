import FindIcon from "@material-ui/icons/FindInPage";
import React from "react";
import EmptyState from "../../components/EmptyState";

export default function NotFoundScreen() {
  return (
    <EmptyState
      icon={<FindIcon />}
      title="Content not found"
      description="The requested URL was not found on this server"
    />
  );
}
