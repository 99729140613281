import { Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import Colors from "./Colors";
import { BodyMedium } from "./typography";

function FooterLink({ title, style, ...props }) {
  return (
    <Link
      {...props}
      style={{
        fontSize: 16,
        paddingRight: 24,
        cursor: "pointer",
        textDecoration: "none",
        color: Colors.Gray.G500,
        fontFamily: "Barlow",
        fontFeatureSettings: "'salt' on",
        ...style,
      }}>
      {title}
    </Link>
  );
}

export default function FooterView() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div
      style={{
        height: 90,
        marginTop: 32,
        padding: matches ? 32 : 16,
        borderTop: "1.5px solid #F4F5F6",
      }}>
      <Grid
        container
        spacing={0}
        style={matches ? { maxWidth: 1120, marginLeft: "auto", marginRight: "auto" } : {}}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          style={{ textAlign: matches ? "left" : "center", paddingBottom: matches ? 0 : 8 }}>
          <FooterLink title="Terms" href="/terms" />
          <FooterLink title="Privacy" href="/privacy" />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} style={{ textAlign: matches ? "center" : "right" }}>
          <BodyMedium
            style={{
              color: Colors.Gray.G500,
              textAlign: matches ? "right" : "center",
              ...(matches ? {} : { fontSize: 12 }),
            }}>
            Copyright ©{new Date().getFullYear()} Post Swag. All rights reserved.
          </BodyMedium>
        </Grid>
      </Grid>
    </div>
  );
}
