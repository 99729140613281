import { Button, Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import Colors from "../../../components/Colors";
import { BodyMedium, Heading2 } from "../../../components/typography";
import AppleIcon from "@material-ui/icons/Apple";

export default function HomePresentHassleFree() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container style={{ maxWidth: 1120 }}>
      <Grid
        style={{
          marginTop: 32,
          minHeight: 370,
          maxWidth: 1120,
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: Colors.Gray.G50,
          border: "1.5px solid " + Colors.Gray.G200,
          borderRadius: 24,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          textAlign: "center",
          backgroundImage: matches ? "url(/images/cta.webp)" : "url(/images/cta.webp)",
        }}>
        <Heading2
          style={{
            color: "#000",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 62,
            paddingLeft: matches ? 62 : 40,
            paddingRight: matches ? 62 : 40,
            marginBottom: 32,
            maxWidth: 810,
            ...(matches ? {} : { fontSize: 24 }),
          }}>
          20+ crop sizes, 20+ picture frames, 20+ image filters and hundreds of text styles
          compination
        </Heading2>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AppleIcon />}
          href="https://apps.apple.com/app/id1669035195"
          style={{ color: "#fff", width: 182 }}>
          Get the App
        </Button>

        <BodyMedium
          style={{
            fontSize: 18,
            color: "#000",
            marginBottom: 48,
            marginLeft: "auto",
            marginRight: "auto",
          }}>
          See jaw-dropping posts!
        </BodyMedium>
      </Grid>
    </Container>
  );
}
