import React from "react";
import Bar from "../../components/Bar/Bar";
import FooterView from "../../components/FooterView";
import FAQs from "./ui/FAQs";
import HomeFeatures from "./ui/HomeFeatures";
import HomeHeader from "./ui/HomeHeader";
import HomePresentHassleFree from "./ui/HomePresentHassleFree";

export default function HomeScreen() {
  return (
    <div style={{ background: "#fff" }}>
      <Bar />
      <HomeHeader />
      <HomeFeatures />

      <FAQs
        title="Frequently Asked Questions"
        items={[
          {
            q: "What is Post Swag?",
            a: "Post Swag is about making it easy for people to create graphics for social media.",
          },
          {
            q: "How much does it cost?",
            a: "It's free for doing the basic and there Pro version where you get all features.",
          },
          {
            q: "How easy it is to create a post?",
            a:
              "As simple as picking an image, crop it, frame it adding text with styles, getting a result ready for sharing!",
          },
        ]}
      />
      <HomePresentHassleFree />
      <FooterView />
    </div>
  );
}
