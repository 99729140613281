import { Button, Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import Colors from "../../../components/Colors";
import { BodyMedium, Heading3, Heading4 } from "../../../components/typography";
import AppleIcon from "@material-ui/icons/Apple";

export default function HomeFeatures() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const headerStyle = matches
    ? {}
    : { fontSize: 35, paddingLeft: 16, paddingRight: 16, lineHeight: "40px" };
  const textStyle = matches ? {} : { fontSize: 14, maxWidth: 210 };

  return (
    <>
      <div id="features" style={{ marginTop: -80, marginBottom: 80 }} />

      <div
        style={{
          paddingTop: 50,
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#fff",
          paddingBottom: 32,
        }}>
        <div style={{ textAlign: "center" }}>
          <Heading3 style={{ marginBottom: 16, ...headerStyle }}>
            What you get by downloading Post&nbsp;Swag
          </Heading3>
        </div>

        <Container style={{ maxWidth: 1120, marginTop: 50 }}>
          <Grid
            container
            style={{
              borderRadius: 24,
              backgroundColor: Colors.Gray.G50,
              border: "1.5px solid " + Colors.Gray.G200,
            }}>
            <Grid item xs={12} sm={5} md={5} lg={5} style={{ padding: matches ? 62 : 40 }}>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
                <Heading4
                  style={{
                    color: "#555",
                    fontSize: matches ? 92 : 32,
                    lineHeight: matches ? "114px" : "50px",
                    textAlign: matches ? "left" : "center",
                  }}>
                  Choose from 20+ popular social media post sizes
                </Heading4>

                <BodyMedium
                  style={{
                    fontSize: 18,
                    color: "#000",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: matches ? "left" : "center",
                  }}>
                  to make posts look professional on all sizes
                </BodyMedium>

                <div style={{ textAlign: matches ? "left" : "center" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<AppleIcon />}
                    href="https://apps.apple.com/app/id1669035195"
                    style={{ marginTop: 20, color: "#fff", width: 180 }}>
                    Get the App
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={7} style={{ padding: 62 }}>
              <div style={{ flex: 1, textAlign: "right" }}>
                <img alt="Crop & Frame" style={{ width: "100%" }} src="images/crop_n_frame.webp" />
              </div>
            </Grid>
          </Grid>
        </Container>

        <Container style={{ maxWidth: 1120, marginTop: 16 }}>
          <Grid container spacing={2} style={{}}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div
                style={{
                  borderRadius: 24,
                  backgroundColor: Colors.Gray.G50,
                  border: "1.5px solid " + Colors.Gray.G200,
                }}>
                <div style={{ padding: matches ? 62 : 40 }}>
                  <Heading4
                    style={{
                      color: "#555",
                      fontSize: 32,
                      height: matches ? 80 : undefined,
                      lineHeight: "38px",
                      textAlign: matches ? "left" : "center",
                    }}>
                    20+ image frames ready to use with no effort!
                  </Heading4>

                  <BodyMedium
                    style={{
                      fontSize: 18,
                      color: "#000",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: matches ? "left" : "center",
                    }}>
                    to make posts like a pro designer
                  </BodyMedium>
                </div>
                <img
                  alt="no-setup"
                  src="images/crop.webp"
                  style={{ width: "100%", paddingLeft: 32, paddingRight: 32, paddingBottom: 32 }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div
                style={{
                  borderRadius: 24,
                  backgroundColor: Colors.Gray.G50,
                  border: "1.5px solid " + Colors.Gray.G200,
                }}>
                <div style={{ padding: matches ? 62 : 40 }}>
                  <Heading4
                    style={{
                      color: "#555",
                      fontSize: 32,
                      height: matches ? 80 : undefined,

                      lineHeight: "38px",
                      textAlign: matches ? "left" : "center",
                    }}>
                    Hundreds of text styles compination
                  </Heading4>
                  <BodyMedium
                    style={{
                      fontSize: 18,
                      color: "#000",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: matches ? "left" : "center",
                    }}>
                    to show your unique taste
                  </BodyMedium>
                </div>
                <img
                  alt="interact"
                  src="images/text-styles.webp"
                  style={{ width: "100%", paddingLeft: 32, paddingRight: 32, paddingBottom: 32 }}
                />
              </div>
            </Grid>
          </Grid>
        </Container>

        <Container style={{ maxWidth: 1120, marginTop: 16 }}>
          <Grid container spacing={2} style={{}}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div
                style={{
                  borderRadius: 24,
                  backgroundColor: Colors.Gray.G50,
                  border: "1.5px solid " + Colors.Gray.G200,
                }}>
                <div style={{ padding: matches ? 62 : 40 }}>
                  <Heading4
                    style={{
                      height: matches ? 80 : undefined,

                      fontSize: 32,
                      color: "#555",
                      lineHeight: "38px",
                      textAlign: matches ? "left" : "center",
                    }}>
                    Dynamic colors! Pick a color get more suggestions
                  </Heading4>
                  <BodyMedium
                    style={{
                      fontSize: 18,
                      color: "#000",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: matches ? "left" : "center",
                    }}>
                    to standout and do exactly what you want
                  </BodyMedium>
                </div>
                <img
                  alt="no-setup"
                  src="images/text-colors.webp"
                  style={{ width: "100%", paddingLeft: 32, paddingRight: 32, paddingBottom: 32 }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div
                style={{
                  borderRadius: 24,
                  backgroundColor: Colors.Gray.G50,
                  border: "1.5px solid " + Colors.Gray.G200,
                }}>
                <div style={{ padding: matches ? 62 : 40 }}>
                  <Heading4
                    style={{
                      height: matches ? 80 : undefined,

                      fontSize: 32,
                      color: "#555",
                      lineHeight: "38px",
                      textAlign: matches ? "left" : "center",
                    }}>
                    20+ image filters to pick from
                  </Heading4>
                  <BodyMedium
                    style={{
                      fontSize: 18,
                      color: "#000",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: matches ? "left" : "center",
                    }}>
                    to make every image works for you
                  </BodyMedium>
                </div>
                <img
                  alt="interact"
                  src="images/image-filters.webp"
                  style={{ width: "100%", paddingLeft: 32, paddingRight: 32, paddingBottom: 32 }}
                />
              </div>
            </Grid>
          </Grid>
        </Container>

        <Container style={{ maxWidth: 1120, marginTop: 16 }}>
          <Grid container spacing={2} style={{}}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div
                style={{
                  borderRadius: 24,
                  backgroundColor: Colors.Gray.G50,
                  border: "1.5px solid " + Colors.Gray.G200,
                }}>
                <div style={{ padding: matches ? 62 : 40 }}>
                  <Heading4
                    style={{
                      height: matches ? 80 : undefined,

                      fontSize: 32,
                      color: "#555",
                      lineHeight: "38px",
                      textAlign: matches ? "left" : "center",
                    }}>
                    Change frame colors
                  </Heading4>
                  <BodyMedium
                    style={{
                      fontSize: 18,
                      color: "#000",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: matches ? "left" : "center",
                    }}>
                    to make stunning effects
                  </BodyMedium>
                </div>
                <img
                  alt="no-setup"
                  src="images/frame-colors.webp"
                  style={{ width: "100%", paddingLeft: 32, paddingRight: 32, paddingBottom: 32 }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div
                style={{
                  borderRadius: 24,
                  backgroundColor: Colors.Gray.G50,
                  border: "1.5px solid " + Colors.Gray.G200,
                }}>
                <div style={{ padding: matches ? 62 : 40 }}>
                  <Heading4
                    style={{
                      height: matches ? 80 : undefined,

                      fontSize: 32,
                      color: "#555",
                      lineHeight: "38px",
                      textAlign: matches ? "left" : "center",
                    }}>
                    Add your own logo!
                  </Heading4>
                  <BodyMedium
                    style={{
                      fontSize: 18,
                      color: "#000",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: matches ? "left" : "center",
                    }}>
                    to tell everyone about your brand
                  </BodyMedium>
                </div>
                <img
                  alt="interact"
                  src="images/logo.webp"
                  style={{ width: "100%", paddingLeft: 32, paddingRight: 32, paddingBottom: 32 }}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
