import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeScreen from "../../features/home/HomeScreen";
import MPrivacyScreen from "../../features/legal/MPrivacyScreen";
import MTermsScreen from "../../features/legal/MTermsScreen";
import PrivacyScreen from "../../features/legal/PrivacyScreen";
import TermsScreen from "../../features/legal/TermsScreen";
import NotFoundScreen from "../../features/notfound";

export default function Router() {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      backgroundColor: "#f9fafa",
    },

    toolbar: theme.mixins.toolbar,

    content: {
      flexGrow: 1,
      backgroundColor: "#f9fafa",
    },
  }));

  const classes = useStyles();

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <div className={classes.root}>
        <main className={classes.content}>
          <Switch>
            <Route path="/" exact>
              <HomeScreen />
            </Route>

            <Route path="/terms">
              <TermsScreen />
            </Route>

            <Route path="/privacy">
              <PrivacyScreen />
            </Route>

            <Route path="/mterms">
              <MTermsScreen />
            </Route>

            <Route path="/mprivacy">
              <MPrivacyScreen />
            </Route>

            <Route>
              <NotFoundScreen />
            </Route>
          </Switch>
        </main>
      </div>
    </BrowserRouter>
  );
}
