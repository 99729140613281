import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import React, { Component } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import appearance from "../services/appearance";
import Router from "./navigation/Router";

export default class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={appearance.defaultTheme}>
        <CssBaseline />

        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
      </MuiThemeProvider>
    );
  }
}
